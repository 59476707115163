
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "incline-client",
  props: ["clientid"],
  components: {},
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;

    const clientInfo = computed(() => {
      return store.getters.getClientInfo(Number(props.clientid));
    });

    const primaryLocation = computed(() => {
      return store.getters.getPrimaryLocation(Number(props.clientid));
    });

    const { d } = useI18n();

    return {
      currentUser,
      clientInfo,
      primaryLocation,
      d,
    };
  },
});
