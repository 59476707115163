<template>
  <div>
    <!--begin::Navbar-->
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <!--begin::Details-->
        <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
          <!--begin: Pic-->
          <div class="me-7 mb-4">
            <div
              class="
                symbol symbol-100px symbol-lg-160px symbol-fixed
                position-relative
              "
            >
              <img src="/media/avatars/incline-avator.png" alt="image" />
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div
              class="
                d-flex
                justify-content-between
                align-items-start
                flex-wrap
                mb-2
              "
            >
              <!--begin::Client-->
              <div class="d-flex flex-column">
                <!--begin::Name-->
                <div class="d-flex align-items-center mb-2">
                  <a
                    href="#"
                    class="text-gray-800 text-hover-primary fs-1 fw-bolder me-1"
                    >{{ clientInfo.name }}
                  </a>
                </div>
                <!--end::Name-->

                <!--begin::Info-->
                <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <p class="d-flex align-items-center text-gray-400 me-5 mb-2">
                    {{ primaryLocation.streetAddress }}
                  </p>
                  <a
                    href="#"
                    class="
                      d-flex
                      align-items-center
                      text-gray-400 text-hover-primary
                      me-5
                      mb-2
                    "
                  >
                    <span class="svg-icon svg-icon-4 me-1">
                      <inline-svg
                        src="media/icons/duotune/general/gen018.svg"
                      />
                    </span>
                    {{ primaryLocation.city }}, {{ primaryLocation.state }}
                  </a>
                </div>
                <!--end::Info-->
              </div>
              <!--end::Client-->
            </div>
            <!--end::Title-->

            <!--begin::Stats-->
            <div class="d-flex flex-wrap flex-stack">
              <!--begin::Wrapper-->
              <div class="d-flex flex-column flex-grow-1 pe-8">
                <!--begin::Stats-->
                <div class="d-flex flex-wrap">
                  <!--begin::Stat-->
                  <div
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      min-w-125px
                      py-3
                      px-4
                      me-6
                      mb-3
                    "
                  >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-success">
                        <inline-svg
                          src="media/icons/duotune/arrows/arr066.svg"
                        />
                      </span>
                      <div class="fs-3 fw-bolder">Employees</div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-5 text-gray-600">
                      {{ clientInfo.numOfEmployees }}
                    </div>
                    <!--end::Label-->
                  </div>
                  <!--end::Stat-->

                  <!--begin::Stat-->
                  <div
                    v-if="
                      currentUser.role == 'SuperAdmin' ||
                      currentUser.role == 'LicensedDistributor' ||
                      currentUser.role == 'LDAssociate'
                    "
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      min-w-125px
                      py-3
                      px-4
                      me-6
                      mb-3
                    "
                  >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-danger">
                        <inline-svg
                          src="media/icons/duotune/arrows/arr065.svg"
                        />
                      </span>
                      <div class="fs-3 fw-bolder">Contract Id</div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-600">
                      {{ clientInfo.clientContractId }}
                    </div>
                    <!--end::Label-->
                  </div>
                  <!--end::Stat-->
                  <!--begin::Stat-->
                  <div
                    v-if="
                      currentUser.role == 'SuperAdmin' ||
                      currentUser.role == 'LicensedDistributor' ||
                      currentUser.role == 'LDAssociate'
                    "
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      min-w-125px
                      py-3
                      px-4
                      me-6
                      mb-3
                    "
                  >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-danger">
                        <inline-svg
                          src="media/icons/duotune/arrows/arr065.svg"
                        />
                      </span>
                      <div class="fs-3 fw-bolder">Service Fee</div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-600">
                      ${{ clientInfo.serviceFee.toFixed(2) }}
                    </div>
                    <!--end::Label-->
                  </div>
                  <!--end::Stat-->
                  <!--begin::Stat-->
                  <div
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      min-w-125px
                      py-3
                      px-4
                      me-6
                      mb-3
                    "
                  >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-danger">
                        <inline-svg
                          src="media/icons/duotune/arrows/arr065.svg"
                        />
                      </span>
                      <div class="fs-3 fw-bolder">Created On</div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-600">
                      {{ d(clientInfo.clientCreatedUtc) }}
                    </div>
                    <!--end::Label-->
                  </div>
                  <!--end::Stat-->
                </div>
                <!--end::Stats-->
              </div>
              <!--end::Wrapper-->
            </div>
            <!--end::Stats-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <!--begin::Navs-->
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'client-overview',
                  params: {
                    clientid: clientid,
                  },
                }"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Overview</router-link
              >
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'client-locations',
                  params: {
                    clientid: clientid,
                  },
                }"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Locations</router-link
              >
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'client-users',
                  params: {
                    clientid: clientid,
                  },
                }"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Users</router-link
              >
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'edit-client',
                  params: {
                    clientid: clientid,
                  },
                }"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Settings</router-link
              >
            </li>
            <!--end::Nav item-->
          </ul>
        </div>
        <!--begin::Navs-->
      </div>
    </div>
    <!--end::Navbar-->
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "incline-client",
  props: ["clientid"],
  components: {},
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;

    const clientInfo = computed(() => {
      return store.getters.getClientInfo(Number(props.clientid));
    });

    const primaryLocation = computed(() => {
      return store.getters.getPrimaryLocation(Number(props.clientid));
    });

    const { d } = useI18n();

    return {
      currentUser,
      clientInfo,
      primaryLocation,
      d,
    };
  },
});
</script>
